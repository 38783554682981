export const works1 = [
  {
    brand: `What is Normal`,
    slug: `what-is-normal`,
    title: `What is Normal?`,
    categories: ["art"],
    thumbnail: "https://dnvu9lyn4yi0.cloudfront.net/WhatIsNormalSmall.png",
  link : "/WhatIsNormal"
},
  {
    brand: `Concentration`,
    slug: `concentration`,
    title: `Concentration`,
    categories: ["art"],
    thumbnail: "https://dnvu9lyn4yi0.cloudfront.net/ConcentrationSmall.png",
  link : "/Concentration"
},
{
  brand: `Frustration`,
  slug: `frustration`,
  title: `Frustration`,
  categories: ["art"],
  thumbnail: "https://dnvu9lyn4yi0.cloudfront.net/FrustrationSmall.png",
link : "/Frustration"
},
{
  brand: `Bacteria`,
  slug: `bacteria`,
  title: `Bacteria`,
  categories: ["art"],
  thumbnail: "https://dnvu9lyn4yi0.cloudfront.net/BacteriaSmall.png",
link : "/Bacteria"
},
{
  brand: `Minimalism`,
  slug: `minimalism`,
  title: `Minimalism`,
  categories: ["art"],
  thumbnail: "https://dnvu9lyn4yi0.cloudfront.net/minimalismsmall.png",
link : "/Minimalism"
},
  {
    brand: `Robot`,
    slug: `robot`,
    title: `Robot`,
    categories: ["art"],
    thumbnail: "https://dnvu9lyn4yi0.cloudfront.net/RobotSmall.png",
  link : "/Robot"
},
  {
    brand: `Airplane`,
    slug: `airplane`,
    title: 'Airplane',
    categories: ["art"],
    thumbnail: "https://dnvu9lyn4yi0.cloudfront.net/AirplaneSmall.png",
  link : "/Airplane"
},
  {
    brand: `Shock`,
    slug: `shock`,
    title: `Shock`,
    categories: ["art"],
    thumbnail: "https://dnvu9lyn4yi0.cloudfront.net/ShockSmall.png",
  link : "/Shock"
},
  {
    brand: `Where is my Mind`,
    slug: `where-is-my-mind`,
    title: `Where is my Mind?`,
    categories: ["art"],
    thumbnail: "https://dnvu9lyn4yi0.cloudfront.net/WhereIsMyMindSmall.png",
  link : "/WhereIsMyMind"
},
  {
    brand: `Deep in the Jungle`,
    slug: `deep-in-the-jungle`,
    title: `Deep in the Jungle.`,
    categories: ["art"],
    thumbnail: "https://dnvu9lyn4yi0.cloudfront.net/DeepInTheJungleSmall.png",
  link : "/DeepInTheJungle"
},
  {
    brand: `Sanderstead`,
    slug: `sanderstead`,
    title: `Sanderstead`,
    categories: ["art"],
    thumbnail: "https://dnvu9lyn4yi0.cloudfront.net/sandersteadSmall.png",
  link : "/Sanderstead"
},
  {
    brand: `Aliens`,
    slug: `aliens`,
    title: `Aliens`,
    categories: ["art"],
    thumbnail: "https://dnvu9lyn4yi0.cloudfront.net/AlienSmall.png",
  link : "/Aliens"
},
  {
    brand: `All I Can See`,
    slug: `all-i-can-see`,
    title: `All I Can See`,
    categories: ["art"],
    thumbnail: "https://dnvu9lyn4yi0.cloudfront.net/AllICanSeeSmall.png",
  link : "/AllICanSee"
},
  {
    brand: `Broken`,
    slug: `broken`,
    title: `Broken`,
    categories: ["art"],
    thumbnail: "https://dnvu9lyn4yi0.cloudfront.net/BrokenSmall.png",
  link : "/Broken"
},
  {
    brand: `Walk`,
    slug: `walk`,
    title: 'Walk',
    categories: ["art"],
    thumbnail: "https://dnvu9lyn4yi0.cloudfront.net/walkSmall.png",
  link : "/Walk"
},
];
