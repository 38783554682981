import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box } from "../components/Core";
import RotateImg from "../components/RotateImg";
import { works1 } from "../data";
import TagManager from 'react-gtm-module'

const IndexPage = () => {
  const tagManagerArgs = {
    gtmId: 'GTM-NG9FV59'
  }

  if (process.browser) {
    TagManager.initialize(tagManagerArgs);
  }

  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(works1);
  }, []);

  return (
    <>
      <PageWrapper>
      <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col lg="8">
                <Title variant="secSm" className="my-4">
                  My paintings.{" "}
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                  My partner and I were living in London, everything was fine, I wasn't nervous around anything. Suddenly I started to feel paranoid and I wasn't sure what was wrong. We were out one day and out of nowhere it hit me. 
                  I was fine. Then I wasn't. 
                  It got worse and it was hard to leave the house. I went to the doctors and turned out I have a Generalised Anxiety Disorder. I had panic attacks which I never experienced before, I was agoraphobic, 
                  paranoid, and I couldn't live my life properly.
                  I moved back home with my lovely family and was put on anti-depressants. Everyone around me was so supportive, and I love them all. 
                  I was trying to find ways to cope, and i've always loved art ever since I was little.
                  One day I painted something that I felt, and I thought it looked quite good, and made me feel better. 
                  Most of these paintings are a reflection of the emotion and feelings experienced within me as I was painting.
                  </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section hero>
          <Container fluid>
            <Row>
              {items.map((item, index) => (
                <Col lg="3" md="4" sm="6" key={index} className="filtr-item">
                  <RotateImg
                    mb="30px"
                    link={item.link}
                    imgSrc={item.thumbnail}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default IndexPage;
